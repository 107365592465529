@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --swiper-theme-color: #008000 !important;
  --swiper-navigation-size: 30px !important;
  /*
  --swiper-preloader-color: var(--swiper-theme-color);
  --swiper-wrapper-transition-timing-function: initial;
  */
}

html::-webkit-scrollbar {
  width: 14px;
}

html::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

html::-webkit-scrollbar-thumb {
  height: 3px;
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #008000;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@layer base {
  h1 {
    @apply text-6xl;
  }

  h2 {
    @apply text-5xl;
  }

  h3 {
    @apply text-4xl;
  }
}


.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@layer components {
  .swiper-before {
    --v-offset: 60px;
    --curve-height: 120px;
    content: "";
    z-index: 99;
    display: block;
    background: white;
    width: calc(100vw + 2 * var(--v-offset));
    height: var(--curve-height);
    position: absolute;
    border-radius: 45%;
    left: calc(-1 * var(--v-offset));
    right: calc(-1 * var(--v-offset));
    top: calc(-0.7 * var(--curve-height));
  }

  .swiper-after {
    --v-offset: 60px;
    --curve-height: 120px;
    content: "";
    z-index: 99;
    display: block;
    background: white;
    width: calc(100vw + 2 * var(--v-offset));
    height: var(--curve-height);
    position: absolute;
    border-radius: 45%;
    left: calc(-1 * var(--v-offset));
    right: calc(-1 * var(--v-offset));
    bottom: calc(-0.7 * var(--curve-height));
  }
}